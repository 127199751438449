<template>
    <div class="editor-container">
      <div id="onlyofficeEditor" ref="onlyofficeEditor"></div>
      <!-- 保存按钮 -->
      <el-button class="save-btn" type="primary" @click="saveFile">保存</el-button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Editor',
    
    data() {
      return {
        editor: null
      }
    },
  
    mounted() {
      this.loadDocsApi().then(() => {
        this.initEditor()
      }).catch(() => {
        this.$message.error('OnlyOffice API 加载失败')
      })
    },
  
    beforeDestroy() {
      if (this.editor) {
        this.editor.destroyEditor()
      }
      const script = document.querySelector('script[src*="api.js"]')
      if (script) {
        script.remove()
      }
    },
  
    methods: {
      saveFile(){
        // this.editor.destroyEditor()
        this.$message.success('保存成功')
        this.$router.push({
          path: '/',
        })
        window.close()
      },
      loadDocsApi() {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.src = `${process.env.VUE_APP_ONLYOFFICE_URL}/web-apps/apps/api/documents/api.js`
          script.onload = resolve
          script.onerror = reject
          document.head.appendChild(script)
        })
      },

      initEditor() {
        if (!window.DocsAPI) {
          this.$message.error('OnlyOffice API 加载失败')
          return
        }
  
        try {
          const config = JSON.parse(decodeURIComponent(this.$route.query.config))
          this.editor = new window.DocsAPI.DocEditor('onlyofficeEditor', config)
        } catch (error) {
          this.$message.error('初始化编辑器失败')
          console.error('初始化编辑器失败:', error)
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .editor-container {
    height: 100vh;
    width: 100vw;
  }
  
  #onlyofficeEditor {
    width: 100%;
    height: 100%;
  }
  
  .save-btn {
    position: absolute;
    bottom: 5%;
    right: 5%;
  }
  </style> 